import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AdSense from "react-adsense";
import ReactGA from "react-ga";
import Layout from "../components/layout";
import { apiUrl, caKey, caSlot, gaKey } from "../utils";

const BookPage = () => {
  document.title = "traffic-e-class | traffic rules book";
  const [headList, setHeadList] = useState(null);
  const [onload, setOnLoad] = useState(true);

  const getBookHead = async () => {
    if (onload) {
      const response = await fetch(apiUrl + "/api/book", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();
      if (response.status === 200) {
        setHeadList(data);
        setOnLoad(false);
      }
    }
  };

  useEffect(() => {
    getBookHead();
    ReactGA.initialize(gaKey);
    ReactGA.pageview(window.location.pathname + window.location.search);
  });
  return (
    <Layout>
      <AdSense.Google
        style={{ display: "block" }}
        client={caKey}
        slot={caSlot}
        format="auto"
        responsive="true"
      />

      <div className="bookBlogs">
        <h1>Traffic Rules</h1>
        <hr />

        {headList !== null ? (
          headList.map((element, index) => (
            <div key={index} className="bookblogCont">
              <div className="heading">
                <div>
                  <h2>{element.heading}</h2>
                </div>
                <div>
                  <h3>{element.title}</h3>
                </div>
              </div>

              <div className="sampleBody">
                <p>{element.description}</p>
                <Link
                  to={`heading/${element.heading}`}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  read more
                </Link>
              </div>
            </div>
          ))
        ) : (
          <div className="error">
            <div className="loader"></div>
            <div className="info">Loading...</div>
          </div>
        )}
      </div>

      <AdSense.Google
        style={{ display: "block" }}
        client={caKey}
        slot={caSlot}
        format="auto"
        responsive="true"
      />
    </Layout>
  );
};

export default BookPage;
