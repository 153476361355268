import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import TagManager from "react-gtm-module";
import Welcome from "./pages/welcome";
import BookPage from "./pages/bookPage";
import Page404 from "./pages/page404";
import SingleBook from "./pages/singleBook";
import Plate from "./pages/plate";
import StartTest from "./pages/startTest";
import TestResult from "./pages/testResult";
import TestContextProvider from "./context/testContext";

function App() {
  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-N3TDXQV" });
  }, []);
  return (
    <CookiesProvider>
      <Router>
        <React.Fragment>
          <Routes>
            <Route path="" exact element={<Welcome />} />
            <Route path="plate" exact element={<Plate />} />
            <Route path="book" exact element={<BookPage />} />
            <Route path="test/*" element={<Test />} />
            <Route path="book/heading/:heading" element={<SingleBook />} />

            <Route path="*" element={<Page404 />} />
          </Routes>
        </React.Fragment>
      </Router>
    </CookiesProvider>
  );
}

const Test = () => {
  return (
    <TestContextProvider>
      <Routes>
        <Route path="" exact element={<StartTest />} />
        <Route path="result" exact element={<TestResult />} />
        <Route path="*" exact element={<Page404 />} />
      </Routes>
    </TestContextProvider>
  );
};

export default App;
