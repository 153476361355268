import React, { useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import AdSense from "react-adsense";
import Question from "../components/question";
import { testContext } from "../context/testContext";
import ReactGA from "react-ga";
import { caKey, caSlot, gaKey } from "../utils";

const TestResult = () => {
  document.title = "traffic-e-class | test result";

  const {
    questions,
    mark,
    setLoading,
    setSelectedSolution,
    setTestNo,
    testNo,
    isSubmitted,
    setisStarted,
    setIsSubmitted,
    setQuestions,
  } = useContext(testContext);

  const navigate = useNavigate();

  const Repeat = () => {
    setIsSubmitted(false);
    setisStarted(true);
    window.scrollTo(0, 0);
    setSelectedSolution([]);
  };

  const Next = () => {
    setTestNo(testNo + 1);
    setIsSubmitted(false);
    setisStarted(true);
    setSelectedSolution([]);
    window.scrollTo(0, 0);
    setQuestions(null);
    setLoading(true);
  };

  useEffect(() => {
    if (!isSubmitted) {
      navigate("/");
    }
    ReactGA.initialize(gaKey);
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  return (
    <div className="result">
      <h1>Test Result</h1>
      <hr />
      <div className="resport">
        {mark >= 12 ? "Congratulation!" : "Fail!"} You have earn {mark}/20
      </div>

      <AdSense.Google
        style={{ display: "block" }}
        client={caKey}
        slot={caSlot}
        format="auto"
        responsive="true"
      />

      {questions !== null ? (
        <div className="nexpre">
          <div className="prev">
            <Link to="/test">
              <button onClick={() => Repeat()}>Repeat</button>
            </Link>
          </div>

          <div className="canceltest">
            <Link to="/">Cancel Test</Link>
          </div>

          <div className="next">
            <Link to="/test">
              <button onClick={() => Next()}>Next</button>
            </Link>
          </div>
        </div>
      ) : (
        <div></div>
      )}

      <div className="answerprev">
        {questions !== null ? (
          questions.map((question, index) => (
            <div key={index}>
              <Question question={question} isSolved={true} />
            </div>
          ))
        ) : (
          <div className="error">
            <div className="loader"></div>
            <div className="info">Loading...</div>
          </div>
        )}
      </div>

      <AdSense.Google
        style={{ display: "block" }}
        client={caKey}
        slot={caSlot}
        format="auto"
        responsive="true"
      />

      {questions !== null ? (
        <div className="nexpre">
          <div className="prev">
            <button onClick={() => Repeat()}>Repeat</button>
          </div>
          <div className="next">
            <button onClick={() => Next()}>Next</button>
          </div>
        </div>
      ) : (
        <div></div>
      )}

      <AdSense.Google
        style={{ display: "block" }}
        client={caKey}
        slot={caSlot}
        format="auto"
        responsive="true"
      />
    </div>
  );
};

export default TestResult;
