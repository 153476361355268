import { useNavigate } from "react-router-dom";
import { StatusCode } from "react-http-status-code";

const Page404 = () => {
  const navigate = useNavigate();
  document.title = "Page not found";
  return (
    <StatusCode code={400}>
      <div className="notfound">
        <div className="mssg">404</div>
        <div className="info">OOPs! Page not found</div>
        <div>
          <button onClick={() => navigate("/")}>back to home</button>
        </div>
      </div>
    </StatusCode>
  );
};

export default Page404;
