import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import AdSense from "react-adsense";
import ReactGA from "react-ga";
import Layout from "../components/layout";
import { apiUrl, caKey, caSlot, gaKey } from "../utils";

const SingleBook = () => {
  const { heading } = useParams();
  const navigate = useNavigate();
  const [onload, setOnLoad] = useState(true);
  const [head, setHead] = useState(null);

  const findHeading = async () => {
    document.title = `traffic-e-class | traffic rules | ${heading}`;
    if (onload) {
      const response = await fetch(`${apiUrl}/api/book/${heading}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();
      if (response.status === 200) {
        setHead(data);
        setOnLoad(false);
      } else {
        navigate(`/book/${heading}`);
      }
    }
  };

  useEffect(() => {
    findHeading();
    ReactGA.initialize(gaKey);
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  return (
    <Layout>
      <div className="blogCont">
        <AdSense.Google
          style={{ display: "block" }}
          client={caKey}
          slot={caSlot}
          format="auto"
          responsive="true"
        />

        {head !== null && true ? (
          <div className="myblog">
            <div className="backnav">
              <Link to="/book">
                <span>&#9001;</span>
              </Link>
            </div>
            <div className="blohd">
              <div className="notsh">
                <h2>{heading} : </h2>
              </div>
              <div>
                <h1>{head.title}</h1>
              </div>
            </div>

            <div className="blogBody">
              {head.body.map((element, index) => (
                <div key={index} className="headsub">
                  <div className="headsubhd">
                    <div>
                      <h5>{element.title} : </h5>
                    </div>
                    <div>
                      <h4>{element.body.title}</h4>
                    </div>
                  </div>
                  {element.body.map((subelement, index) => (
                    <div key={index}>
                      {subelement.body.map((minelement, index) => (
                        <div key={index} className="partCont">
                          <h6>{minelement.title}</h6>
                          <div className="pcont">
                            {minelement.body.map((pelement, index) => (
                              <p key={index}>{pelement.toString()}</p>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="error">
            <div className="loader"></div>
            <div className="info">Loading...</div>
          </div>
        )}

        <AdSense.Google
          style={{ display: "block" }}
          client={caKey}
          slot={caSlot}
          format="auto"
          responsive="true"
        />
      </div>
    </Layout>
  );
};

export default SingleBook;
