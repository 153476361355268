import React, { useState, useEffect, createContext } from "react";
import { apiUrl } from "../utils";

export const testContext = createContext();

const TestContextProvider = ({ children }) => {

    const [questions, setQuestions] = useState(null);
    const [selectedSolution, setSelectedSolution] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isStarted, setisStarted] = useState(true);
    const [mark, setMark] = useState(0);
    const [testNo, setTestNo] = useState(1);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const get_questions = async () => {
        if(loading) {
            const response = await fetch(apiUrl + '/api/', {
                headers: {
                'Content-Type': 'application/json',
                }
            });

            const data = await response.json();

            if (response.status === 200) {
                setQuestions(data);
                setLoading(false);
            }
            else {
                setQuestions(null);
            }
        }
    }

    const getQuestionIndex = (question) => {
        var n = -1;
        questions.forEach((element, index) => {
            if(JSON.stringify(element.question) === JSON.stringify(question)){
                n = index;
            }
        });

        return n;
    }

    const setSolution = (question, choice) => {
        var index = getQuestionIndex(question);
        var temp = [...selectedSolution];
        temp[index] = choice;
        setSelectedSolution(temp);
    }

    const isChosen = (choice) => {
        for(var x of selectedSolution) {
            var stringChoice = JSON.stringify(choice);
            if(stringChoice === JSON.stringify(x)){
                return true;
            }
        }
        return false;
    }

    const getMarks = () => {
        var count = 0;
        selectedSolution.forEach(element => {
            if(element) {
                if(element.truth) {
                    count++;
                }
            }
        });

        setMark(count);
    }

    const contextData = {
        questions,
        setQuestions,
        selectedSolution,
        setSolution,
        isChosen,
        isStarted,
        setisStarted,
        mark,
        isSubmitted,
        setIsSubmitted,
        getMarks,
        setLoading,
        setSelectedSolution,
        testNo,
        setTestNo,
    }

    useEffect(() => {
        setTimeout(() => {
            get_questions();
        }, 300);
        
    })

  return (
    <testContext.Provider value={contextData}>
        {children}
    </testContext.Provider>
  );
};

export default TestContextProvider;