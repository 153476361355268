import React from "react";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  return (
    <div className="navbar">
      <div>
        <h1>TRAFFIC RULES E-CLASS</h1>
      </div>
      <div className="nv">
        <div className="navig">
          <NavLink to="/">Home</NavLink>
          <NavLink to="/test">Test</NavLink>
          <NavLink to="/plate">Road Signs</NavLink>
          <NavLink to="/book">Traffic Rules</NavLink>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
