import { useEffect } from "react";
import { Link } from "react-router-dom";
import AdSense from "react-adsense";
import Layout from "../components/layout";
import ReactGA from "react-ga";
import { caKey, caSlot, gaKey } from "../utils";

const Welcome = () => {
  document.title = "traffic-e-class | Home";

  useEffect(() => {
    ReactGA.initialize(gaKey);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Layout> 
      <div className="welcomepage">
        <div className="intro">
          <h2>Test your ability to take Provisional exam</h2>
          <hr />
          <p>
            Practice makes perfect as we all know. Doing many test on this
            website, it will show your ability to attend the Provisional Driving
            License exam. Taking more test can help you to improve your skill.
          </p>

          <p>
            By clicking start quiz, you will only have 20 minutes to complete
            your test. When time is out, test will be submitted automatically.
            Start and make your work clear.
          </p>
          <Link to="/test" onClick={() => window.scrollTo(0, 0)}>
            Start Quiz
          </Link>
        </div>

        <AdSense.Google
          style={{ display: "block" }}
          client={caKey}
          slot={caSlot}
          format="auto"
          responsive="true"
        />

        <div className="intro">
          <h2>Learn Roadsigns plates</h2>
          <hr />
          <p>
            In this section, you can go deep through Roadsigns with there
            meaning. Roadsigns are classified into 5 categories that are
            Roadsigns for danger and priority, Prohibition and obligation
            signals and indication signals.
          </p>
          <Link to="/plate" onClick={() => window.scrollTo(0, 0)}>
            Roadsigns Course
          </Link>
        </div>

        <AdSense.Google
          style={{ display: "block" }}
          client={caKey}
          slot={caSlot}
          format="auto"
          responsive="true"
        />

        <div className="intro">
          <h2>Read Traffic Rules</h2>
          <hr />
          <p>
            Here, you will find all contents about regulating general traffic
            polic and road traffic. It includes Premiminary provisions(area of
            applications, definition, qualified, agents, accidents,
            orders-requisitions-signalling and driving licence), Traffic(general
            rules and special rules applicable to specific users),
            Vehicles(Dimesions, loading, maximum weights authorized, lines of
            vehicles, exceptional transports, braking, lighting and signalling
            of vehicles and animals and other prescriptions ),
          </p>

          <p>
            Signalling(general provisions, road signs, traffic lghts, marks on
            the road, singalling of work sites and obstacles, miscellaneous
            provisions), Vehicles identifications(vehicles registration,
            registrations certificate, registration number, distinctive sign and
            identifications marks, renewal of plates and registration
            certificates, placing and legibility of registration plates and of
            distinctive sign, Indication on some vehicles of names, residence
            and the recording number in the trade register and indication of the
            maximum authorised number of passengers,
          </p>

          <p>
            traffic of vehicles registered out of Rwanda, vehicles on test,
            cycles, mopeds and man-propelled vehicles, taxes ), Automobile
            technical control departmentt(automobile techinical control
            enterprises, categories of vehicles subject to technical control,
            control organistion), National road security committee(composition,
            functioning), Impounding, Transitional, measures, repeal and
            implementation.
          </p>
          <Link to="/book" onClick={() => window.scrollTo(0, 0)}>
            Road traffic course
          </Link>
        </div>

        <AdSense.Google
          style={{ display: "block" }}
          client={caKey}
          slot={caSlot}
          format="auto"
          responsive="true"
        />
      </div>
    </Layout>
  );
};

export default Welcome;
