import React, { useContext } from "react";
import { testContext } from "../context/testContext";

const Question = (props) => {
  const questionChoice = props.question;
  const isSolved = props.isSolved;

  const { setSolution, isChosen } = useContext(testContext);

  const question = questionChoice.question;
  const choices = questionChoice.choice;
  const alpbabet = Array.from("ABCDEFGHJKL");

  const getName = (choice) => {
    if (choice.truth) {
      return " trcan";
    }
    return "";
  };

  return (
    <div key={question.id}>
      <div className="question">
        <p>{question ? question.question_text : ""}</p>
      </div>

      <div className="choices">
        {choices ? (
          choices.map((choice) => (
            <div
              key={choice.id}
              className={"nch"}
              onClick={() => (!isSolved ? setSolution(question, choice) : null)}
            >
              <div className={"count" + getName(choice)}>
                <div
                  className={isChosen(choice) ? "countCh chosen" : "countCh"}
                >
                  <div className={isChosen(choice) ? "chosen" : ""}>
                    {alpbabet[choices.indexOf(choice)]}
                  </div>
                </div>
              </div>

              <div className="chtxt">
                <p>{choice.choice_text}</p>
              </div>
            </div>
          ))
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default Question;
