import React, { useState } from "react";
import { apiUrl } from "../../utils";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const sendFeedBack = async (email, message) => {
    const response = await fetch(apiUrl + "/api/feedback", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        message: message,
      }),
    });

    if (response.status === 200) {
      alert("feedback send successful");
    } else {
      alert("fail to send feedback");
    }
  };

  const isEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    if (isEmail(email) && message.length !== 0) {
      sendFeedBack(email, message);
    } else {
      alert("enter the valid data");
    }

    setEmail("");
    setMessage("");
  };

  return (
    <div className="footer">
      <div className="feedback">
        <div className="hd">
          <p>Feedback</p>
        </div>
        <form onSubmit={(event) => submitHandler(event)}>
          <div className="input">
            <input
              type="email"
              required
              placeholder="Your email..."
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </div>

          <div className="input">
            <textarea
              rows="4"
              placeholder="Message..."
              value={message}
              onChange={(event) => setMessage(event.target.value)}
            ></textarea>
          </div>

          <div className="subtn">
            <button type="submit">Send</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Footer;
