import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import AdSense from "react-adsense";
import Question from "../components/question";
import { testContext } from "../context/testContext";
import ReactGA from "react-ga";
import { caKey, caSlot, gaKey } from "../utils";

const StartTest = () => {
  document.title = "traffic-e-class | test";

  const {
    questions,
    selectedSolution,
    getMarks,
    testNo,
    setisStarted,
    setIsSubmitted,
  } = useContext(testContext);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [count, setCount] = useState({ min: "00", sec: "00" });
  const [timeLeft, setTimeLeft] = useState(60 * 1000 * 20);

  const navigators = [];
  for (var n = 0; n < 20; n++) {
    var isActive = n === Number(selectedIndex) ? "active" : "";
    var isAnswered = selectedSolution[n] !== undefined ? "answered" : "";
    var element = (
      <div
        className={isAnswered + " " + isActive}
        key={n}
        id={n}
        onClick={(event) => {
          if (true) {
            setSelectedIndex(event.target.id);
          }
        }}
      >
        {n + 1}
      </div>
    );
    navigators.push(element);
  }

  const submitHandler = () => {
    getMarks();
    setTimeLeft(0);
    setisStarted(false);
    setIsSubmitted(true);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      const newTime = timeLeft - 1000;
      if (timeLeft > -1 && questions !== null) {
        setTimeLeft(newTime);
        var inDate = new Date(timeLeft);
        var min = inDate.getMinutes();
        var sec = inDate.getSeconds();

        if (min < 10) {
          min = "0" + min;
        }

        if (sec < 10) {
          sec = "0" + sec;
        }
        setCount({ min: min, sec: sec });
        if (timeLeft === 0) {
          submitHandler();
        }
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
      ReactGA.initialize(gaKey);
      ReactGA.pageview(window.location.pathname + window.location.search);
    };
  });

  return (
    <div className="test">
      <h1>Traffic Test</h1>
      <hr />
      <div className="nmcount">
        <div className="testnum">
          <p>Test No {testNo}</p>
        </div>

        <div className="canceltest">
          <Link to="/">Cancel Test</Link>
        </div>

        <div className="testcount">
          <p>
            Time: {count.min}:{count.sec}
          </p>
        </div>
      </div>

      <AdSense.Google
        style={{ display: "block" }}
        client={caKey}
        slot={caSlot}
        format="auto"
        responsive="true"
      />

      <div className="testboard">
        {questions ? (
          <>
            <Question question={questions[selectedIndex]} isSolved={false} />

            <div className="nexpre">
              <div className="prev">
                <button
                  onClick={(event) => {
                    if (Number(selectedIndex) > 0) {
                      var current = Number(selectedIndex);
                      setSelectedIndex(--current);
                    }
                  }}
                >
                  ❮ Previous
                </button>
              </div>
              <div className="next">
                <button
                  onClick={() => {
                    if (Number(selectedIndex) < 19) {
                      var current = Number(selectedIndex);
                      setSelectedIndex(++current);
                    }
                  }}
                >
                  Next ❯
                </button>
              </div>
            </div>
          </>
        ) : (
          <div className="error">
            <div className="loader"></div>
            <div className="info">Loading...</div>
          </div>
        )}

        <div className="qnav">{navigators.map((navig) => navig)}</div>

        {questions !== null ? (
          <div className="subtnq">
            <Link to="result">
              <button onClick={() => submitHandler()}>Submit</button>
            </Link>
          </div>
        ) : (
          <div></div>
        )}
      </div>

      <AdSense.Google
        style={{ display: "block" }}
        client={caKey}
        slot={caSlot}
        format="auto"
        responsive="true"
      />
    </div>
  );
};

export default StartTest;
