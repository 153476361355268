import { useState, useEffect } from "react";
import AdSense from "react-adsense";
import ReactGA from "react-ga";
import Layout from "../components/layout";
import { apiUrl, caKey, caSlot, gaKey } from "../utils";

const Plate = () => {
  document.title = "traffic-e-class | plates";

  const [plates, setPlates] = useState(null);
  const [onLoad, SetOnLoad] = useState(true);
  const get_plates = async () => {
    if (onLoad) {
      const response = fetch(apiUrl + "/api/plates", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log(response);

      if ((await response).status === 200) {
        const data = (await response).json();
        setPlates(await data);
        SetOnLoad(false);
      }
    }
  };

  useEffect(() => {
    get_plates();
    ReactGA.initialize(gaKey);
    ReactGA.pageview(window.location.pathname + window.location.search);
  });
  return (
    <Layout>
      <div className="plate_plan">
        <AdSense.Google
          style={{ display: "block" }}
          client={caKey}
          slot={caSlot}
          format="auto"
          responsive="true"
        />

        <div className="plateCont">
          <h1>Roadsigns and their meanings</h1>
          {plates != null ? (
            plates.map((plate) => (
              <div key={plate.id} className="plCont">
                <div className="plate_img">
                  <img src={`${plate.image}`} alt="plate" />
                </div>
                <div className="plate_meaning">
                  <p>{plate.meaning}</p>
                </div>
              </div>
            ))
          ) : (
            <div className="error">
              <div className="loader"></div>
              <div className="info">Loading...</div>
            </div>
          )}
        </div>

        <AdSense.Google
          style={{ display: "block" }}
          client={caKey}
          slot={caSlot}
          format="auto"
          responsive="true"
        />
      </div>
    </Layout>
  );
};

export default Plate;
